<!--  -->
<template>
  <div class="bodyStyle" v-title data-title="1111">
    <div class="contentStyle" >
      <h1>公安备案中......</h1>
    </div>
    <BottomCommon/>
  </div>
</template>

<script>
import BottomCommon from "@/components/Common/BottomCommon.vue"
export default {
  components: {
    BottomCommon
  },
  data () {
    return {
    }
  }
}
</script>

<style  scoped>
  .bodyStyle {
    width:100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  .contentStyle {
    width:100%;
    min-height: calc(100vh - 100px);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
