<!--  -->
<template>
  <div class="bottomStyle">
    <a href="https://beian.miit.gov.cn/" target="_blank">ICP证:京ICP备2024072938号-1</a>
  </div>
</template>

<script>
export default {
  name: 'BottomCommon',
  data () {
    return {

    }
  }
}

</script>

<style  scoped>
  .bottomStyle{
    width: 100vw;
    height: 100px;
    background-color: rgb(233, 233, 233);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

</style>
